import React, {useEffect} from 'react'
import Layout from '../components/shared/Layout'
import SEO from '../components/seo'

const Images = () => {

    useEffect(() => {
        // Update the document title using the browser API
        window.$('.modal-image').on('click', function() {
            window.$('.enlargeImageModalSource').attr('src', window.$(this).attr('src'));
            window.$('#enlargeImageModal').modal('show');
          });        
      });
    

    return (
    <Layout>
        <SEO title="Bilder" />
        <div className="wrap" role="document">
            <div className="content">

                <main className="main" id="main">
                    <header className="header">
                        <div className="container">
                            <div className="header__caption">
                                <h1>Profilbilder</h1>
                            </div>
                        </div>
                    </header>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="submenu">
                                    <ul className="submenu__list">
                                        <li className="submenu__item"><a href="#brand-images">Profilbilde</a></li>
                                        <li className="submenu__item"><a href="#concept-images">Konseptbilder</a></li>
                                        <li className="submenu__item"><a href="#signature-images">Signaturbilder</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9">

                                <p className="ingress lead">
                                    Profilbildene forteller historien om hvilken verden Saga ­KL befinner seg i. Helheten av motiv, farger, stil og tone skal avspeile kjedens kjerneverdier.
                                    Motivene er inspirert av rådgivere, kontormiljøer og betraktninger rundt dette. 
                                </p>
                                <p className="ingress lead">
                                    
                                </p>

                                <hr/>

                                <div id="brand-image" className="section">
                                    <h2>Profilbilder</h2>
                                    <p>
                                        Bildene skal søke å ha et hovedfokus som er skarpt og en bakgrunn 
                                        (evt. også forgrunn) som er uskarp (gjerne Motion Blur-effekt). 
                                        Dette for å skape god dynamikk og for å gi hoved­motivet ro. 
                                        Motivene kan være vennlige rådgivere, ofte med tillitsskapende 
                                        øyekontakt, eller nærbilder av hender i relevant arbeid. Det 
                                        kan også være bilder uten personer som f.eks. PC’er, 
                                        regneark eller kontorrekvisita.
                                    </p>
                                    <p>
                                        Det etterstrebes et kjølig og nordisk lys, med stor vekt på blå- og gråtoner. Kontrasten i bildene skal være høy.
                                    </p>
                                    <p>
                                        Profilbildene skal også ha et fargefilter av hovedprofilfargen burgunder. Dette legges på i hjørnene nede til venstre og oppe til høyre, og skal legges inn med “Multiply”-effekt og passende opacity.
                                    </p>
                                    <p>
                                        Burgunder-effekten kan lastes ned som egen psd-fil.
                                    </p>
                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Burg.%20bildeeffekt.psd.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned burgunder effekt</a>
                                    <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_1.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid"/>
                                </div>

                                <hr/>

                                <div id="concept-images" className="section">
                                    <h2>Konseptbilder</h2>
                                    <div className="container no-padding">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_1.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_1.jpg" rel="noreferrer" target="_blank" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_2.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/> 
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_2.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_3.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/> 
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_3.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_4.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/> 
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_4.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_5.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_5.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_6.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_6.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_7.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_7.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_8.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_8.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_9.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_9.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_10.jpg" alt="Saga KL Profilmanual Profilbilde 1000x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_konseptbilde_10.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div id="signature-images" className="section">
                                    <h2>Signatur</h2>
                                    <p>
                                        Disse bildene bruker vi for å eksponere logoen vår. Omrisset av logosymbolet rammer inn tre horisontale former i motivet. Dette danner logobokstaven S.
                                        <br/>
                                        Bildene kan brukes som dekor i materiell som plakater, display og trykksaker. 
                                    </p>
                                    <img className="img-fluid" src="../img/sections/grafiske-profilelementer/element_5.jpg" alt="Saga KL Profilmanual VERTIKAL LOGO 900x600"/>
                                <br/>
                                <br/>
                                    <div className="container no-padding">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_signaturbilde_1.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/> 
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_signaturbilde_1.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_signaturbilde_3.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/>
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_signaturbilde_3.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img src="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_signaturbilde_2.jpg" alt="Saga KL Profilmanual Profilbilde 900x600" className="img-fluid modal-image"/> 
                                                <div className="btn-group">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/images/Saga_Services_signaturbilde_2.jpg" target="_blank" rel="noreferrer" className="button button--image"><i className="fa fa-arrow-down icon--image"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div className="modal fade" id="enlargeImageModal" tabIndex="-1" role="dialog" aria-labelledby="enlargeImageModal" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <img alt="enlarge" src="" className="enlargeImageModalSource" style={{width: "100%"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </div>
    </Layout>
)}

export default Images